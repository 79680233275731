/*! Page Header and Navigation
---------------------------------------------- */
.page-header {
  position: relative;
  z-index: 8;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 20px;

  text-transform: uppercase;

  font-weight: 900;
  font-size: 16px;
  line-height: 1;

  color: $dark;
  background: #fff;

  .lang {
    display: none;
    min-width: 260px;
  }

  @media (min-width: $md-break) {
    padding: 1rem 25px;

    .lang {
      display: block;
    }
  }

  .phone {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-transform: none;
    
    font-size: 14px;

    @media (min-width: $md-break) {
      font-size: 18px;
    }
    
    a:first-child {
      padding-bottom: 10px;
    }
  }

  .logo {
    img {
      display: block;
      width: 100%;
      max-width: 70px;

      @media (min-width: $md-break) {
        max-width: 120px;
      }
    }
  }
}

.main-nav {
  position: relative;
  z-index: 8;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  padding-right: 20px;
  
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  
  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }

  @media (min-width: $md-break) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 25px;
    
    padding-right: 0;
  }

  background: $darker;

  .nav-links,
  .nav-links-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    list-style-type: none;

    width: 100%;
    padding: 0;
    margin: 0;

    color: #fff;
    font-weight: 900;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;

    display: none;
    @media (min-width: $md-break) {
      display: flex;
    }

    .nav-link-item {
      margin-right: 40px;

      transition: all 0.35s ease;

      &.is-active {
        color: $colorAccent1;
      }

      @media (min-width: 768px) {
        &:hover {
          color: $colorAccent1;
        }
      }
    }
  }

  .nav-cta {
    display: none;
    margin-left: 2rem;
    border-radius: 0;

    @media (min-width: $md-break) {
      display: inline-block;
    }
  }
  
  .nav-cta.mobile-nav-cta {
    display: block;
    margin: 0;
    width: calc(100% - 50px);
  }

  .mobile-only {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    @media (min-width: $md-break) {
      display: none;
    }

    .lang {
      margin-right: auto;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
    }
  }
}


.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: #F0F0F0;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .logo {
      height: 60px;

      img {
        display: block;
        widows: auto;
        height: 60px;
      }
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(#000, 0.075);

    a {
      margin-bottom: 10px;
      color: $dark;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      background-color: $dark;
      padding: 15px;
      border-radius:3px;
      color: #fff;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0 20px 15px;
    border-bottom: 1px solid rgba(#000, 0.075);

    a {
      font-size: 14px;
      margin-bottom: 35px;
      color: $dark;
      text-transform: uppercase;
      text-align: center;

      &.site-btn {
        color: white;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    svg {
      margin-right: 20px;
      height: 24px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.mobile-menu-trigger {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url('/img/icon-burger.svg') no-repeat center center / contain;

  @media (min-width: $md-break) {
    display: none;
  }
}

.navigation-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($darker, 0.70);

  opacity: 0;

  &.is-active {
    opacity: 1;
    z-index: 15;
  }
}
