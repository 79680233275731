/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

/*! Hero
---------------------------------------------- */
.hero {
  position: relative;
  background: $colorAccent1;
  color: #fff;

  overflow: hidden;

  display: flex;
  flex-direction: column-reverse;
  padding: 0;

  @media (min-width: $md-break) {
    display: block;
    padding: 1rem 5vw;
  }

  .hero-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    .hero-content {
      width: 100%;

      @media (min-width: $md-break) {
        width: 50%;
      }
    }

    .hero-content {
      padding: 5vw 20px;

      @media (min-width: $md-break) {
        padding: 4vw 2vw;
      }
    }

    .big-title {
      max-width: 555px;
    }

    .medium-text {
      max-width: 455px;
    }

    .site-btn {
      display: inline-block;
    }
  }

  .hero-image {
    position: relative;
    width: 100%;

    @media (min-width: $md-break) {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      display: block;
      object-fit: cover;
      height: 100%;
    }
  }
}

/*! Arguments
---------------------------------------------- */
.arguments {
  padding-top: 35px;
  padding-bottom: 35px;

  text-align: center;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .medium-title {
    margin-bottom: 55px;
  }

  .arguments-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;

    margin-bottom: 0;

    @media (min-width: $md-break) {
      margin-bottom: 35px;
    }

    .argument-icon {
      width: auto;
      height: 80px;
      margin-bottom: 25px;
    }

    .argument-item {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 15px 0;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @media (min-width: $md-break) {
        width: calc(100% / 4);
        padding: 25px 35px;
      }

      .arrow-right {
        position: absolute;
        top: 15%;
        right: -15px;

        width: 30px;

        display: none;
        @media (min-width: $md-break) {
          display: block;
        }
      }

      &:last-child {
        .arrow-right {
          display: none;
        }
      }
    }
  }
}

/*! Products
---------------------------------------------- */
.products {
  padding-top: 35px;
  padding-bottom: 35px;

  text-align: center;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .medium-title {
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 25px;

    @media (min-width: $md-break) {
      margin-bottom: 0;
    }
  }

  &.is-center {
    .products-list {
      justify-content: center;

      .product-item {
        margin: 0 35px;
      }
    }
  }

  .products-list {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    flex-wrap: wrap;

    .product-icon {
      width: auto;
      height: 80px;
      margin-bottom: 25px;
    }

    .product-item {
      position: relative;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 25px;

      width: 100%;

      @media (min-width: $md-break) {
        width: calc(85% / 3);
        padding: 20px;
        margin-bottom: 0;
      }

      img {
        display: block;
        width: 70%;
        margin: 0 auto;

        @media (min-width: $md-break) {
          width: 80%;
        }
      }

      .small-title {
        margin-bottom: 0;
      }

      .product-price {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 15px 0;
        margin: 0 10px;
        margin-bottom: 15px;

        border-top: 1px solid rgba(#4B3939, 0.5);
        border-bottom: 1px solid rgba(#4B3939, 0.5);

        font-weight: 600;
        color: $dark;

        .price {
          min-width: 100px;
          text-align: left;
          font-size: 20px;

          color: $colorAccent1;

          &:last-child {
            text-align: right;
          }
        }

        .separator {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .product-item.forfait-1,
    .product-item.forfait-53 {
      img {
        width: 65%;
      }
    }

    .product-item.forfait-2,
    .product-item.forfait-100 {
      img {
        width: 85%;
        top: 0;
        position: relative;

        @media (min-width: $md-break) {
          width: 95%;
          top: -16px;
          position: relative;
        }
      }
    }

    .small-title {
      padding: 20px 0;
    }

    .regular-text {
      margin-bottom: 15px;

      @media (min-width: $md-break) {
        margin-bottom: 45px;
      }
    }

    .site-btn {
      display: block;
    }
  }
}

/*! Articles
---------------------------------------------- */
.articles {
  padding-top: 35px;
  padding-bottom: 35px;

  text-align: center;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &.no-padding {
    padding-bottom: 0;
  }

  .medium-title {
    max-width: 270px;
    margin: 0 auto;
    margin-bottom: 75px;
  }

  .articles-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;

    margin-bottom: 30px;

    &.no-padding {
      margin-bottom: 0;
    }

    .article-image {
      display: block;
      width: 100%;
    }

    .article-item {
      width: 100%;
      text-align: center;
      background: #fff;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $md-break) {
        width: calc(95% / 3);
        margin-bottom: 3%;
      }

      .article-content {
        padding: 15px;

        @media (min-width: $md-break) {
          padding: 30px;
        }
      }

      .article-price {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        margin-bottom: 30px;

        text-align: left;
        color: $dark;

        .small-title {
          max-width: 80%;
          margin: 0;
          padding: 0;
          line-height: 1;
          font-size: 16px;
          padding-bottom: 5px;
        }

        .left {
          min-width: 70%;

          span {
            text-decoration: line-through;
          }
        }

        .right {
          min-width: 100px;
          text-align: right;
        }

        .price {
          min-width: 100px;
          font-size: 20px;
          font-weight: 600;

          color: $colorAccent1;
        }
      }

      .site-btn {
        display: block;
      }
    }

    .article-image {
      display: block;
      width: 100%;
    }
  }
}

/*! Split
---------------------------------------------- */
.split {
  padding-top: 35px;
  padding-bottom: 35px;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .split-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;

    @media (min-width: $md-break) {
      margin-bottom: 45px;
    }

    &:last-child {
      margin-bottom: 0;

      @media (min-width: $md-break) {
        margin-bottom: 20px;
      }
    }

    &.is-reverse {
      flex-direction: row-reverse;

      .split-content {
        padding: 25px 0;
        padding-left: 0;
        padding-right: 0;

        @media (min-width: $md-break) {
          padding-left: 0;
          padding-right: 45px;
        }
      }
    }

    .split-image,
    .split-content {
      width: 100%;

      @media (min-width: $md-break) {
        width: 50%;
      }
    }

    .split-image {
      img {
        display: block;
        width: 100%;
      }
    }

    .regular-text {
      margin-bottom: 20px;
    }

    .split-content {
      padding: 25px 0;
      padding-right: 0;

      @media (min-width: $md-break) {
        padding: 45px;
        padding-right: 0;
      }
    }
  }
}

/*! Split Slider
---------------------------------------------- */
.split-slider {
  position: relative;
  padding: 0;
  overflow: hidden;

  @media (min-width: $md-break) {
    padding: 1rem 5vw;
  }

  .site-btn {
    display: inline-block;
  }

  .small-title {
    font-size: 14px;
  }

  .colors-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;

    .color {
      width: 65px;
      text-align: center;

      @media (min-width: $md-break) {
        width: 75px;
      }

      img {
        display: inline-block;
        max-width: 40px;

        @media (min-width: $md-break) {
          max-width: 50px;
        }
      }

      .small-title {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .split-item {
    position: relative;
    top: 255px;
    margin-bottom: 255px;
    display: flex;
    justify-content: flex-end;
    background: #fff;

    @media (min-width: $md-break) {
      margin-bottom: 45px;
      top: 0;
    }

    .split-content {
      width: 100%;
      padding: 20px;

      @media (min-width: $md-break) {
        width: 50%;
        padding: 75px 55px;
        margin-bottom: 0;
      }

      .regular-text {
        margin: 0 0 25px 0;
      }

      .price {
        margin: 0 0 25px 0;
        color: $dark;
        font-size: 20px;

        span {
          color: $colorAccent1;
        }

        small {
          display: inline-block;
          padding-left: 10px;
          text-decoration: line-through;
        }

        .infos {
          font-size: 12px;
        }
      }
    }
  }

  .slider-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 65vw;

    @media (min-width: $md-break) {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
    }

    .main-carousel,
    .carousel-cell {
      width: 100%;
      height: 100%;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &.slider-monuments {
      .main-carousel,
      .carousel-cell {
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          margin: 0 auto;
        }
      }
    }
  }
}

/*! CTA
---------------------------------------------- */
.cta {
  padding-top: 35px;
  padding-bottom: 35px;

  text-align: center;
  color: #fff;

  background: $darker;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .cta-title {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 45px;
  }

  .site-btn {
    display: inline-block;
  }
}

/*! FAQ
---------------------------------------------- */
.faq {
  padding-top: 35px;
  padding-bottom: 35px;

  @media (min-width: $md-break) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  text-align: center;

  .medium-title {
    max-width: 270px;
    margin: 0 auto;
    margin-bottom: 75px;
  }

  .faq-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;

    .faq-item {
      width: 100%;
      text-align: center;
      background: #fff;
      margin-bottom: 25px;
      padding: 35px 20px;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $md-break) {
        width: calc(95% / 3);
        margin-bottom: 3%;
        padding: 55px 40px;
      }

      .small-title {
        position: relative;
        margin: 0;
        padding: 0;
        line-height: 1.2;
        font-size: 18px;
        padding-bottom: 5px;
        margin-bottom: 25px;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -15px;
          transform: translate(0, 0);
          width: 50px;
          height: 4px;
          background-color: $colorAccent1;
          z-index: 1;
        }
      }

      .regular-text {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

/*! Footer
---------------------------------------------- */
.footer {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;

  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: $dark;
  background: $light;

  @media (min-width: $md-break) {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/*! Aside form
---------------------------------------------- */
.aside-form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($darker, 0.70);

  opacity: 0;
}

.aside-form {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 85%;
  max-width: 500px;
  z-index: 10;

  background: #fff;

  transform: translate(100%, 0);

  transition: all 0.45s ease;

  .aside-form-header {
    position: relative;
    padding: 20px 20px;

    background: $darker;
    color: #fff;

    @media (min-width: $md-break) {
      padding: 20px 35px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

      width: 15px;

      cursor: pointer;

      @media (min-width: $md-break) {
        right: 35px;
      }
    }

    h3 {
      width: 100%;
      padding: 0;
      margin: 0;

      color: #fff;
      font-weight: 900;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;

      @media (min-width: $md-break) {
        font-size: 16px;
      }

      &:after {
        display: none;
      }
    }
  }

  .aside-form-content {
    padding: 20px 20px;
    height: 100%;
    overflow-y: scroll;

    @media (min-width: $md-break) {
      padding: 45px 35px;
    }

    .product-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;

      margin-top: -15px;
      margin-bottom: 15px;

      img {
        display: block;
        width: 65px;
        margin-right: 10px;
        border-radius: 60px;
      }

      .small-title {
        font-size: 14px;
      }
    }
  }
}

.aside-form-active {
  .aside-form-overlay {
    opacity: 1;
    z-index: 9;
  }

  .aside-form {
    transform: none;
  }
}

