/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

.fluid-container {
  padding: 1rem 20px;

  @media (min-width: $md-break) {
    padding: 1rem 5vw;
  }
}

.max-container {
  max-width: 1400px;
  margin: 0 auto;
}

::selection {
  background-color: $colorAccent1;
  color: #fff;
}

::-moz-selection {
  background-color: $colorAccent1;
  color: #fff;
}

.is-center {
  text-align: center;
}

.is-light {
  background: $light;
}
