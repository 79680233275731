

.content-pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 800;
  padding: 40px 20px;

  color: $dark;
  background: #f4f3f3;

  a {
    width: 30px;
    height: 30px;
    padding: 10px;
    line-height: 0.80;
    font-weight: 400;
  }

  .icon {
    width: 15px;
  }

  a,
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background: transparent;
    color: $dark;
    text-decoration: none;

    span {
      position: relative;
      top: -1px;
      line-height: 1;
    }

    svg {
      fill: $dark;

      path {
        fill: $dark;
      }
    }

    &.content-pagination-prev,
    &.content-pagination-next {
      width: 45px;

      i {
        color: white;
        pointer-events: none;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;

      }
    }

    &.content-pagination-prev {
      margin-right: 25px;

      &.is-disabled {
        opacity: 0.45;
      }

      span {
         .icon-right,
         .icon-left {
           color: #fff;
         }

        &.is-disabled {
          opacity: 0.45;
        }
      }
    }

    &.content-pagination-next {
      margin-left: 25px;

      &.is-disabled {
        opacity: 0.45;
      }

      span {
        .icon-right,
        .icon-left {
          color: #fff;
        }

        &.is-disabled {
          opacity: 0.45;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      width: 31px;
      height: 31px;
      padding: 0;
      line-height: 1;
      background: $colorAccent3;
      color: #fff;
      text-indent: 0.10rem;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.loader-container {
  position: relative;
  width: 100%;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
