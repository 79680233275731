/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-family: $fontText;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

.big-title {
  padding: 0;
  margin: 0 0 15px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 1.1;

  & strong {
    display: inline;
    font-weight: 700;

    @media (min-width: $md-break) {
      display: block;
    }
  }

  @media (min-width: $md-break) {
    font-size: 41px;
    line-height: 1.2;
  }
}

.medium-title {
  position: relative;
  padding: 0;
  margin: 0 0 15px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.1;
  color: $dark;

  @media (min-width: $md-break) {
    font-size: 32px;
    line-height: 1.2;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translate(-50%, 0);
    width: 50px;
    height: 4px;
    background-color: $colorAccent1;
    z-index: 1;
  }

  & strong {
    display: inline;
    font-weight: 700;

    @media (min-width: $md-break) {
      display: block;
    }
  }
}

.regular-title {
  position: relative;
  padding: 0;
  margin: 0 0 45px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.1;
  color: $dark;

  @media (min-width: $md-break) {
    font-size: 30px;
    line-height: 1.2;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -25px;
    transform: translate(0, 0);
    width: 50px;
    height: 4px;
    background-color: $colorAccent1;
    z-index: 1;
  }

  & strong {
    display: inline;
    font-weight: 700;

    @media (min-width: $md-break) {
      display: block;
    }
  }
}

.small-title {
  padding: 0;
  margin: 0 0 15px 0;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: $dark;

  @media (min-width: $md-break) {
    font-size: 19px;
    line-height: 1.2;
  }

  & strong {
    display: inline;
    font-weight: 700;

    @media (min-width: $md-break) {
      display: block;
    }
  }
}

.medium-text {
  padding: 0;
  margin: 0 0 25px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;

  @media (min-width: $md-break) {
    font-size: 19px;
    line-height: 1.7;
    margin: 0 0 45px 0;
  }
}

.regular-text {
  padding: 0;
  margin: 0 0 25px 0;

  @media (min-width: $md-break) {
    margin-bottom: 45px;
  }

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.7;

  color: $dark;
}

.regular-list {
  padding: 0 0 0 0;
  margin: 0 0 25px 0;

  @media (min-width: $md-break) {
    margin-bottom: 45px;
  }

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;

  color: $dark;

  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 5px 0;

    svg {
      width: 25px;
      margin-right: 15px;
    }
  }
}
