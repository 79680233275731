/*! Site Buttons
---------------------------------------------- */
.site-btn {
  display: block;
  padding: 15px 20px;

  border-radius: 3px;
  border: 2px solid $colorAccent1;
  background: $colorAccent1;
  font-weight: 900;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-align: center;

  transition: all 0.6s ease;

  word-break: none;
  min-inline-size: fit-content;

  @media (min-width: $md-break) {
    display: inline;
    padding: 20px 25px;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    &:hover {
      background: #fff;
      color: $colorAccent1;
    }
  }

  &.is-white {
    background: #fff;
    border-color: #fff;
    color: $dark;

    &:hover {
      opacity: 0.65;
    }
  }

  &.is-dark {
    background: $darker;
    border-color: $darker;
    color: #fff;

    @media (min-width: 768px) {
      &:hover {
        background: #fff;
        color: $darker;
      }
    }
  }

  &.is-outline {
    background: none;
    color: #fff;

    @media (min-width: 768px) {
      &:hover {
        background: #fff;
        color: #002278;
        opacity: 1;
      }
    }
  }
}

.site-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;

  text-align: center;
  text-decoration-line: underline;
  color: $colorAccent1;

  transition: all 0.6s ease;

  @media (min-width: $md-break) {
    font-size: 28px;
  }

  @media (min-width: 768px) {
    &:hover {
      opacity: 0.65;
    }
  }
}
